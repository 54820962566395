import { memo, type ReactNode } from 'react'
import { NoOrusAgents } from '../../templates/no-orus-agents'
import { Page } from '../../templates/page'

/**
 * Wrapper page to use for all subscription pages
 */
export const SubscriptionPageV2 = memo<{ children: ReactNode }>(function SubscriptionPageV2({ children }) {
  return (
    <NoOrusAgents>
      <Page>{children}</Page>
    </NoOrusAgents>
  )
})
